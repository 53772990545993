import debbie from '../../assets/images/DEBBIE BUSICARDS.png';
import fringe from '../../assets/images/FRINGE TSHIRTS.png';
import mask4 from '../../assets/images/Mask Group 4.png';
import mask6 from '../../assets/images/Mask Group 6.png';
import mask7 from '../../assets/images/Mask Group 7.png';
import mask8 from '../../assets/images/Mask Group 8.png';
import mask9 from '../../assets/images/Mask Group 9.png';
import btbgrain from '../../assets/images/btbgrain.png';
import logos010101 from '../../assets/images/logos-01-01-01.png';
import logos02 from '../../assets/images/logos-02.png';
import logos03 from '../../assets/images/logos-03.png';
import logos04 from '../../assets/images/logos-04.png';
import logos05 from '../../assets/images/logos-05.png';
import logos0602 from '../../assets/images/logos-06-02.png';
import lzt07 from '../../assets/images/lzt-07.png';
import zep from '../../assets/images/zep.jpg';

export type ShowcaseWork = {
  slug: string;
  name: string;
  description: string;
  brief: string;
  img: string;
  simple: boolean;
  imgs: string[];
};

export const showcaseWorks: ShowcaseWork[] = [
  {
    slug: 'adelaide-fringe',
    name: 'The Fire of Adelaide',
    description: '2021 poster entry',
    brief: `‘Firestarter’ was a concept I created for the Adelaide Fringe Festival 2021 poster competiton. 

The concept represents the Adelaide Fringe’s role in ‘lighting the fire in Adelaide’ every year by facilitating excitement and culture within the city that is often though of as quite dormant. My entry was voted into the top 40, and was voted for by 100+ people`,
    img: fringe,
    simple: false,
    imgs: [],
  },
  {
    slug: 'debbie-seater',
    name: 'Debbie Seater',
    description: 'branding/stationery',
    brief: `Debbie is an Artist and Art Therapist who was looking for a brand refresh, as her previous business cards were hand made using marbled paper. While her creativity was expressed through these, often parts of the paper would be quite dark, and would make some of the important text illegible.

Debbie felt it was important that we displayed some of her art on her business card to reflect the work she does. The use of the arch in the design reflects the openness she has towards her Art Therapy clients, and reflects her open guiding techniques in her work.`,
    img: debbie,
    simple: false,
    imgs: [],
  },
  {
    slug: 'lucky-zeps',
    name: 'Lucky Zep’s',
    description: 'branding/apparel',
    brief: `Lucky Zep’s is a restaurant and bar that focuses on paying homage to retro style, yet appealing to a younger audience.

Inspired by Vegas neon signs and retro diners, a logo was created, along with printed merchandise for staff and commercial sale, and an additional simple promo GIF/video for use on their website and socials.

The logo was inspired by the owners dog, Zep. His personality has been captured through the use of playful illustrations and bright colour.`,
    img: lzt07,
    simple: false,
    imgs: [],
  },
  {
    slug: 'logos',
    name: 'Logos',
    description: 'branding/typography',
    brief: `Lucky Zep’s is a restaurant and bar that focuses on paying homage to retro style, yet appealing to a younger audience.

Inspired by Vegas neon signs and retro diners, a logo was created, along with printed merchandise for staff and commercial sale, and an additional simple promo GIF/video for use on their website and socials.

The logo was inspired by the owners dog, Zep. His personality has been captured through the use of playful illustrations and bright colour.`,
    img: logos0602,
    simple: true,
    imgs: [logos04, logos010101, logos05, logos0602, logos03, logos02],
  },
];
export const gridWorks = [
  {
    // slug: 'telephone',
    name: 'RING RING',
    // description: 'Hello?',
    // brief: '',
    img: mask6,
  },
  {
    // slug: 'dog',
    name: 'GOOD BOY',
    // description: '*pant pant*',
    // brief: '',
    img: mask9,
  },
  {
    // slug: 'bite-the-bullet',
    name: 'BITE THE BULLET',
    // description: 'Bite it!',
    // brief: '',
    img: btbgrain,
  },
  {
    // slug: 'first-rodeo',
    name: 'NOT MY FIRST RODEO',
    // description: 'Tote',
    // brief: '',
    img: mask4,
  },
  {
    // slug: 'spread-love',
    name: 'POLLINATE YOUR PEERS',
    // description: 'Incredible',
    // brief: '',
    img: mask7,
  },
  {
    // slug: 'sad-clown',
    name: 'SAD CLOWN',
    // description: '*weep weep*',
    // brief: '',
    img: mask8,
  },
];

export const works = [
  // Sneaky
  {
    // slug: 'zep',
    name: 'zep',
    // description: 'dog',
    // brief: 'a good boy',
    img: zep,
  },
  ...showcaseWorks,
  ...gridWorks,
];

export const workBySlug = (slug?: string) => showcaseWorks.find((work) => work.slug === slug);
