import { createStyles } from 'petald';
import { HomeProps, HomeStyleProps } from './Home.types';

type RuleNames = keyof HomeStyleProps;

export const useStyles = createStyles<RuleNames, HomeProps>({
  absolute: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  message: {
    width: 'fit-content',
    position: 'relative',
    transform: 'translate(-52px, -80px)',
  },
  star: {
    position: 'absolute',
    display: 'flex',
  },
  images: {
    // maxWidth: '900px',
    width: '100%',
  },
  image: {
    '&:hover': {
      transform: 'scale(1.015)',
    },
  },
  hand: {
    position: 'absolute',
    right: '-20px',
    top: '-366px',
    animation: '1.5s ease-in-out 0.5s infinite normal none running $animation-move',
  },
  stick: {
    animation: '1.5s ease-in-out 0.5s infinite normal none running $animation-tweak',
  },
  '@keyframes animation-move': {
    '0%': {
      transform: 'translateX(40px)',
    },
    '49%': {
      transform: 'translateX(40px)',
    },
    '50%': {
      transform: 'translateX(0px)',
    },
    '100%': {
      transform: 'translateX(0px)',
    },
  },
  '@keyframes animation-tweak': {
    '0%': {
      transform: 'rotate(-4deg)',
    },
    '49%': {
      transform: 'rotate(-4deg)',
    },
    '50%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
});
