import { Box, Grid, Image, Space, Typography } from 'petald';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as IconStar } from '../../assets/icons/star.svg';
import stick from '../../assets/images/Group 15.png';
import hand from '../../assets/images/hand.png';
import { gridWorks, showcaseWorks } from '../../utils/constants/works';
import { useMobile } from '../../utils/hooks';
import { useStyles } from './Home.styles';

export const Home = () => {
  const classes = useStyles();
  const [englese, setEnglese] = useState(false);
  const navigate = useNavigate();

  const mobile = useMobile();

  return (
    <Space
      direction='vertical'
      gap={mobile ? 100 : 200}
      style={{ maxWidth: '1920px', width: 'calc(100% - 40px)', padding: '0 20px' }}
    >
      <Space direction='vertical' gap={20}>
        <Space
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: mobile ? 'column' : 'row',
          }}
        >
          <Box className={classes.stick}>
            <img src={stick} style={{ width: '100%', height: '100%' }} />
          </Box>
          <Box
            onClick={() => setEnglese(!englese)}
            className={classes.message}
            style={{ transform: mobile ? 'translate(0, -80px)' : 'translate(-52px, -80px)' }}
          >
            <Typography variant='h1' style={{ fontFamily: 'tuppence', whiteSpace: 'nowrap' }}>
              {englese ? 'this is' : 'ceci n`est'}
            </Typography>

            <Space
              direction='vertical'
              style={{ marginTop: '-12px', transform: 'translateX(40px)' }}
            >
              <Typography variant='h1' style={{ transform: 'translateX(12px)' }}>
                {englese ? 'NOT' : 'PAS'}
              </Typography>
              <Typography variant='h1' style={{ marginTop: mobile ? '-24px' : '-40px' }}>
                {englese ? 'STICK' : 'BÂTON'}
              </Typography>
            </Space>

            <Box
              className={classes.star}
              style={{ bottom: mobile ? '-48px' : '-104px', left: mobile ? '-140px' : '-180px' }}
            >
              <IconStar height={mobile ? 112 : undefined} />

              <Box className={classes.absolute} style={{ top: mobile ? -20 : -64 }}>
                <Typography variant='h1' style={{ fontFamily: 'tuppence' }}>
                  {englese ? 'a' : 'un'}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Space>

        <Grid
          gap={mobile ? 12 : 24}
          style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))' }}
        >
          {showcaseWorks.slice(0, 2).map((work) => (
            <Image
              key={work?.slug}
              className={classes.image}
              src={work?.img}
              onClick={() => navigate(`/work/${work?.slug}`)}
              style={{
                root: { paddingTop: '70.0606%' },
                skeleton: { borderRadius: '78px' },
                image: { cursor: 'pointer' },
              }}
            />
          ))}
        </Grid>
      </Space>

      <Space direction='vertical' gap={0}>
        <Space style={{ position: 'relative', flexWrap: 'wrap', rowGap: 0 }} gap='large'>
          <IconStar
            style={{
              position: 'absolute',
              left: '-124px',
              top: mobile ? '-40px' : '-72px',
              zIndex: -1,
            }}
            height={mobile ? 112 : undefined}
          />
          <Typography variant={mobile ? 'h2' : 'h1'} style={{ zIndex: 2 }}>
            ILLUSTRATION
          </Typography>
          <Typography variant='h2' style={{ fontFamily: 'tuppence' }}>
            work
          </Typography>

          {!mobile && (
            <Box className={classes.hand}>
              <img src={hand} style={{ height: '100%', width: '100%' }} />
            </Box>
          )}
        </Space>

        {/* Images */}
        <Grid
          className={classes.images}
          columns={mobile ? 2 : 3}
          rows={mobile ? 3 : 2}
          gap={mobile ? 12 : 24}
        >
          {gridWorks.map((work) => (
            <Image
              key={work?.name}
              className={classes.image}
              src={work?.img}
              // onClick={() => navigate(`/work/${work?.slug}`)}
              style={{ skeleton: { borderRadius: '78px' } }}
            />
          ))}
        </Grid>
      </Space>
    </Space>
  );
};
