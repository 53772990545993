import { Box, Grid, Image, Space, Typography } from 'petald';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as IconStar } from '../../assets/icons/star.svg';
import { showcaseWorks } from '../../utils/constants/works';
import { useMobile } from '../../utils/hooks';
import { useStyles } from './Simple.styles';
import { SimpleProps } from './Simple.types';

export const Simple = ({ work }: SimpleProps): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const mobile = useMobile();

  return (
    <Box
      className={classes.root}
      style={{ maxWidth: '1920px', width: 'calc(100% - 40px)', padding: '0 20px' }}
    >
      <Space direction='vertical' gap={124}>
        <Grid gap={24} style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))' }}>
          <Space direction='vertical' gap={120}>
            <Space direction='vertical' gap={0} style={{ width: 'fit-content' }}>
              <Typography variant='h3' style={{ fontFamily: 'tuppence', textAlign: 'right' }}>
                {work.description}
              </Typography>
              <Box style={{ position: 'relative' }}>
                <IconStar
                  style={{
                    position: 'absolute',
                    top: mobile ? '-40px' : '-102px',
                    left: '-120px',
                    zIndex: -1,
                  }}
                  height={mobile ? 112 : undefined}
                />
                <Typography variant='h2'>{work.name}</Typography>
              </Box>
            </Space>
          </Space>
        </Grid>

        <Box
          style={{
            maxWidth: '1440px',
            width: 'calc(100% - 40px)',
            padding: '0 20px',
            margin: 'auto',
          }}
        >
          <Grid columns={2}>
            {work.imgs.map((img) => (
              <Image key={img} src={img} />
            ))}
          </Grid>
        </Box>

        {/* <Space direction='vertical' gap={40}>
          <Typography variant='h3' style={{ fontFamily: 'tuppence' }}>
            more
          </Typography>
          <Grid
            gap={mobile ? 12 : 24}
            style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))' }}
          >
            {showcaseWorks
              .filter((w) => w.slug !== work.slug)
              .map((work) => (
                <Image
                  key={work?.slug}
                  className={classes.image}
                  src={work?.img}
                  onClick={() => navigate(`/work/${work?.slug}`)}
                  style={{ skeleton: { borderRadius: '78px' } }}
                />
              ))}
          </Grid>
        </Space> */}
      </Space>
    </Box>
  );
};

Simple.displayName = 'Simple';
