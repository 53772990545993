import { Box, Grid, Image, Space, Typography } from 'petald';
import { useNavigate, useParams } from 'react-router-dom';
import { COLORS } from '../../AppTheme';
import { ReactComponent as IconStar } from '../../assets/icons/star.svg';
import { Simple } from '../../layouts/Simple';
import { showcaseWorks, workBySlug } from '../../utils/constants/works';
import { useMobile } from '../../utils/hooks';
import { NotFound } from '../NotFound';
import { useStyles } from './Work.styles';
import { WorkProps } from './Work.types';

export const Work = ({ className = '', style = {} }: WorkProps): JSX.Element => {
  const classes = useStyles({ style });
  const { slug } = useParams();
  const navigate = useNavigate();

  const work = workBySlug(slug);

  if (!work) {
    return <NotFound />;
  }
  const mobile = useMobile();

  if (work.simple) {
    return <Simple work={work} />;
  }

  return (
    <Box
      className={`${classes.root} ${className}`}
      style={{ maxWidth: '1920px', width: 'calc(100% - 40px)', padding: '0 20px' }}
    >
      <Space direction='vertical' gap={124}>
        <Grid gap={24} style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))' }}>
          <Space direction='vertical' gap={120}>
            <Space direction='vertical' gap={0} style={{ width: 'fit-content' }}>
              <Typography variant='h3' style={{ fontFamily: 'tuppence', textAlign: 'right' }}>
                {work.description}
              </Typography>
              <Box style={{ position: 'relative' }}>
                <IconStar
                  style={{
                    position: 'absolute',
                    top: mobile ? '-40px' : '-102px',
                    left: '-120px',
                    zIndex: -1,
                  }}
                  height={mobile ? 112 : undefined}
                />
                <Typography variant='h2'>{work.name}</Typography>
              </Box>
            </Space>
            <Typography
              variant='h5'
              style={{
                whiteSpace: 'pre-wrap',
                color: COLORS.STICKWHACK_BLUE,
                fontFamily: 'Bicyclette',
                padding: '0 40px',
              }}
            >
              {work.brief}
            </Typography>
          </Space>
          <Image src={work.img} style={{ skeleton: { borderRadius: '78px' } }} />
        </Grid>

        {/* <Space direction='vertical' gap={40}>
          <Typography variant='h3' style={{ fontFamily: 'tuppence' }}>
            more
          </Typography>
          <Grid
            gap={mobile ? 12 : 24}
            style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))' }}
          >
            {showcaseWorks
              .filter((w) => w.slug !== work.slug)
              .map((work) => (
                <Image
                  key={work?.slug}
                  className={classes.image}
                  src={work?.img}
                  onClick={() => navigate(`/work/${work?.slug}`)}
                  style={{ skeleton: { borderRadius: '78px' } }}
                />
              ))}
          </Grid>
        </Space> */}
      </Space>
    </Box>
  );
};

Work.displayName = 'Work';
